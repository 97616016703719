<template>
  <v-card class="centered-in-window" width="275">
    <v-card-title class="justify-center white--text primary">
      Вход
    </v-card-title>
    <v-card-text class="pb-0 pt-3">
      <v-form v-model="dataValid">
        <v-row>
          <v-col cols="12" class="py-0">
            <v-text-field
              label="Email"
              ref="email"
              v-model="loginForm.email"
              :rules="[rules.required, rules.email]"
              >
            </v-text-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-text-field
              label="Пароль"
              v-model="loginForm.password"
              :rules="[rules.required, rules.min(loginForm.password, 6)]"
              type="password"
              >
              <template v-slot:append>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon color="error" to="/password-reset-request" v-on="on">
                      <v-icon>mdi-account-question-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Нажмите для восстановления пароля</span>
                </v-tooltip>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="pt-0">
      <v-row>
        <v-col cols="12" class="pt-0 text-center">
          <v-btn color="success" @click="login(loginForm)" :loading="loading" :disabled="loading||!dataValid">
            Войти
          </v-btn>
        </v-col>
        <v-col cols="12" class="grey--text font-weight-thin text-center pa-0">
          Еще не зарегистрированны?
          <router-link to="/user/account/signup">
            Регистрация
          </router-link>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import rules from '@/libs/validate-rules.js'
import { mapActions } from 'vuex'

export default {
  data: () => ({
    loginForm: {
      email: '',
      password: ''
    },
    rules: rules,
    dataValid: false,
  }),
  computed: {
    loading() {
      return !!this.$store.state.apiRequestCounter
    },
  },
  methods: {
    ...mapActions('user', ['login']),
    enterPress(e) {
      if (e.key == 'Enter' && this.dataValid) {
        this.login(this.loginForm)
      }
    }
  },
  mounted() {
    this.$refs.email.focus()
  },
  created: function () {
    window.addEventListener('keydown', this.enterPress)
  },
  destroyed: function () {
    window.removeEventListener('keydown', this.enterPress)
  }
}
</script>

<style>
  .centered-in-window {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
